<!--人才详情-->
<template>
  <div class="bestPerson">
    <div class="breadcrumb w-1400">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/xiangningqing' }"
          >乡宁情</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/Personnelfile' }"
          >人才列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>人才详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="p-list w-1400">
      <div class="floor">
        <div class="floor-h3">
          <i class="iconfont icon-fuzhushuxian"></i> 基本信息
        </div>
        <div class="floor-content clearfix">
          <table class="fl">
            <tr>
              <td>真实姓名：{{pageData.name}}</td>
              <td>性别：{{pageData.gender}}</td>
            </tr>
            <tr>
              <td>出生年月：{{pageData.birthyear}}</td>
              <!-- <td>年龄：{{pageData.age}}</td> -->
            </tr>
            <tr>
              <td>是否党员：{{pageData.partymember}}</td>
              <td>名族：{{pageData.nationality}}</td>
            </tr>
            <tr>
              <td>工作单位：{{pageData.address}}</td>
            </tr>
          </table>
          
        </div>
        <div class="f1-pic ">
          <el-image
            style="width: 100%; height: 100%"
            fit="cover"
            :src="pageData.photoUrls"
          ></el-image>
        </div>
      </div>
      <div class="floor">
        <div class="floor-h3">
          <i class="iconfont icon-fuzhushuxian"></i> 职务职称信息
        </div>
        <div class="floor-text">
     {{pageData.jobInfo}}
        </div>
      </div>
      <div class="floor">
        <div class="floor-h3">
          <i class="iconfont icon-fuzhushuxian"></i> 荣誉信息
        </div>
        <div class="floor-text">
        {{pageData.honorInfo}}
        </div>
      </div>
      <div class="floor">
        <div class="floor-h3">
          <i class="iconfont icon-fuzhushuxian"></i> 学术信息
        </div>
        <div class="floor-text">
         {{pageData.academicInfo}}
        </div>
      </div>
      <div class="floor">
        <div class="floor-h3">
          <i class="iconfont icon-fuzhushuxian"></i> 其他信息
        </div>
        <div class="floor-text">
         {{pageData.otherInfo}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import thirdNav from "@/components/thirdNav";
import { GetPersonnelfile } from "@/api/xiangningqing";
export default {
  components: {
    thirdNav,
  },
    data(){
        return{
            pageData: {},
        }
    },
  mounted() {
    this.getPageDetail();
  },
  methods: {
    getPageDetail() {
      GetPersonnelfile(this.$route.query.id).then((res) => {
        if (res.data?.success == true) {
          this.pageData = res.data.data;
        }
      });
    },
  },
};
</script>
<style scoped>
.floor-text {
  font-size: 16px;
  text-indent: 30px;
  line-height: 30px;
}
.f1-pic {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 180px;
  height: 200px;
  z-index: 20;
}
.floor-content td {
  height: 32px;
  font-size: 16px;
}
.floor {
  border: 1px solid #777;
  padding: 20px;
  position: relative;
  min-height: 200px;
}
.floor-h3 i {
  font-size: 22px;
}
.floor-h3 {
  line-height: 42px;
  font-size: 22px;
  color: #0e6ac1;
}
.bestPerson {
  font-family: "微软雅黑";
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>
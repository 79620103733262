<!--能人列表-->
<template>
    <div class="bestPerson">
        <div class="breadcrumb w-1400">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/xiangningqing'}">乡宁情</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/talents'}">能人事迹展播</el-breadcrumb-item>
                <el-breadcrumb-item >事迹列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="p-list w-1400">
            <div class="p-1f">
                <div class="p-h4"><i class="arrow_right"></i> 事迹推荐</div>
                <div class="mt-20">
                    <div class="carousel-box">
                        <div class="w-box">
                            <ul class="carousel-inner clearfix" :style="`width:${carData.length*307.5}px;transform:translateX(${translateNum}px)`">
                                <li class="carousel-item fl" v-for="(item,index) in carData" :key="index+'ii'">
                                    <div class=" carousel-item-pic">
                                        <router-link :to="`/detail?id=${item.id}&type=能人事迹&oParams=${JSON.stringify(pageParams)}`">
                                            <el-image style="width:100%;height:100%" :src="baseUrl+item.photoUrls"></el-image>
                                        </router-link>
                                    </div>
                                    <div class=" carousel-item-info">
                                        <div class="shijitj-h4 ellipsis">{{item.title}}</div>
                                        <div class="shijitj-text">
                                            {{regHtml(item.content)}}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="btn-prev" @click="prev" :class="{disabled:translateNum==0}"><i class="el-icon-arrow-left"></i></div>
                        <div class="btn-next" @click="next" :class="{disabled:translateNum==carData.length*(-307.5)+1230}"><i class="el-icon-arrow-right"></i></div>
                    </div>
                </div>
            </div>
            <div class="p-2f mt-20 player">
                <div class="p-h4"><i class="arrow_right"></i> 展播列表</div>
                <div class="list-bottom clearfix mt-10">
                    <div class="fl min-box" v-for="item in NRSJList.list" :key="item.id" fl>
                        <div class="mid-item">
                            <div class="mid-pic">
                                <el-image style="width:100%;height:100%" :src="item.photoUrls[0]"></el-image>
                            </div>
                            <div class="ceng"></div>
                            <div class="T_video" >
                                <img width="32px" :src="require('../../../../public/img/video/play.png')" @click="onVideoPlay(`${item.videoUrl}`)" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <el-pagination class="pagination" background layout="prev, pager, next,jumper" :total="NRSJList.total" @current-change="handleCurrentChangeV"> </el-pagination>
            </div>
            <div class="p-2f mt-20">
                <div class="p-h4"><i class="arrow_right"></i> 事迹列表</div>
                <div class="list-bottom clearfix mt-10">
                    <div class="fl min-box" v-for="item in pageData.list" :key="item.id" fl>
                        <div class="mid-item">
                            <div class="mid-pic">
                                <router-link :to="`/detail?id=${item.id}&type=能人事迹&oParams=${JSON.stringify(pageParams)}`">
                                    <el-image style="width:100%;height:100%" :src="baseUrl+item.photoUrls"></el-image>
                                </router-link>
                            </div>
                            <div class="mid-title ellipsis">{{item.title}}</div>
                        </div>
                    </div>
                </div>
                <el-pagination class="pagination" background layout="prev, pager, next,jumper" :total="pageData.total" @current-change="handleCurrentChange"> </el-pagination>
            </div>
        </div>
        <div style="height:50px"></div>
        <!--视频播放窗口-->
        <el-dialog title="" :visible.sync="dialogVideo" width="840px" class="videodlog" append-to-body @close="onVideoClear">
            <i class="el-icon-close icon_btn" @click="dialogVideo = false" style="background:transparent;position:absolute;top:5px;right:10px;font-size:24px;z-index:99999;color:#fff"></i>
            <div class="videoplayer" v-if="dialogVideo">
                <video-player class="bideo-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
            </div>
            <div class="video_List clearfix">
                <ul>
                    <li v-for="item in videoList" class="fl" :key="item+'_key'" style="margin-left:15px">
                        <div class="video_box">
                            <!-- <video :src="item" style="width:100%;height:100%"></video> -->
                            <el-image style="width: 100%; height: 100%" fit="cover" :src="item.photoUrls[0]"></el-image>
                            <div class="video_shade"><img :src="require('../../../../public/img/video/play.png')" @click="onVideoPlay(item)"></div>
                        </div>
                        <div class="video_title">{{item.substr(item.lastIndexOf('\\')+1)}}</div>
                    </li>
                </ul>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import thirdNav from '@/components/thirdNav'
import {GetTalents,GetVideoInfoPage} from '@/api/xiangningqing'
import {regHtml} from '@/utils/reg'
export default {
    components:{
        thirdNav,
    },
    data(){
        return{
            carData:[{content:''},{content:''},{content:''},{content:''},{content:''},],
            translateNum:0,
            dialogVideo: false,
            videoList:[],
            NRSJsparams:{
                currentPage: 1,
                filter: {title: "", infoType: 256581677129797,recommendTypes:'推荐'},
                pageSize: 8,
            },
            pageParams:{
                currentPage: 1,
                filter: {title: "", infoType: 256581677129797},
                pageSize: 10,
            },
            videoParams:{ currentPage: 1, pageSize: 10 , filter: { type: "能人事迹"}},
            pageData:{},
            NRSJList:{},
        }
    },
    computed:{
        baseUrl:()=>process.env.VUE_APP_PHOTO_URL,
        regHtml:()=>regHtml,
    },
    methods:{
        GetVideo() {
            const params = { currentPage: 1, pageSize: 10 , filter: { type: "能人事迹", infoType: 256581677129797 }, };
            GetVideoInfoPage(this.videoParams).then((res) => {
                res.data.data.list.forEach(item=>{
                    item.photoUrls = item.photoUrls.split(',')
                })
                this.NRSJList = res.data.data;
            });
        },
        prev(){
            if(this.translateNum==0) return false;
            this.translateNum += 307.5;
        },
        next(){
            if(this.translateNum==this.carData.length*(-307.5)+1230) return false;
            console.log(this.translateNum)
            this.translateNum -= 307.5;
        },
        //能人事迹
        getNRSJData(){
            GetTalents(this.NRSJsparams).then(res=>{
                this.carData = res.data.data.list;
            })
        },
        //能人列表
        getPageList(){
            GetTalents(this.pageParams).then(res=>{
                this.pageData = res.data.data;
            })
        },
        handleCurrentChangeV(page) {
            this.videoParams.currentPage = page;
            this.GetVideo();
        },
        handleCurrentChange(page) {
            this.pageParams.currentPage = page;
            this.getPageList();
        },
        onVideoClear(){
            this.videoList = [];
        },
        onVideoPlay(path){
            let _src = Array.isArray(path)?path[0]:path;
            let option = {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: true, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: "video/mp4", // 类型
                        src: _src // url地址
                    },
                ],
                poster: '', // 封面地址
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            }
            if(Array.isArray(path)&&path.length>1){this.videoList = path};
            this.playerOptions = option;
            this.dialogVideo = true;
        },
    },
    created(){
        this.getNRSJData();
        this.getPageList();
        this.GetVideo();
    }
}
</script>
<style scoped>
.ceng{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .4);
}
.T_video{
    cursor: pointer;
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.player .mid-pic{
    height: 160px;
}
.list-bottom{
    margin: 0 -10px;
}
.min-box{
    width: 20%;
    padding:10px;
}
.mid-title{
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    padding: 0 8px;
    left: 0;
    bottom: 0;
    color: #fff;
    background-image: linear-gradient(to top,rgba(0, 0, 0,.9) 0%,rgba(0, 0, 0,.7) 40%, transparent);
}
.mid-pic{
    height: 260px;
}
.mid-item{
    margin-bottom: 20px;
    position: relative;
    transition: transform .3s;
}
.mid-item:hover{
    transform: scale(1.05);
}
.big-title{
    line-height: 48px;
    font-size: 20px;
    color: #fff;
    background: rgba(0, 0, 0, .6);
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
}
.big-p1{
    position: relative;
}
.big-p1{
    height: 500px;
}
.shijitj-text{
    font-size: 15px;
    line-height: 24px;
    color: #666;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
    padding: 0 10px;
}
.shijitj-h4{
    line-height: 40px;
    font-size: 18px;
    font-weight: 600;
    padding: 0 8px;
}
.btn-prev{
    left: 0;
}
.btn-next{
    right: 0;
}
.btn-prev.disabled,.btn-prev.disabled:hover,.btn-next.disabled,.btn-next.disabled:hover{
    color: #ccc;
}
.btn-prev:hover,.btn-next:hover{
    color: #1c91ff;
    background: #f1f1f1;
    opacity: .8;
}
.btn-prev,.btn-next{
    width: 40px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    font-size: 40px;
    color: #0169ca;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #eee;
    cursor: pointer;
    opacity: .3;
}
.carousel-inner{
    width: 1800px;
    transition: transform 1.5s;
}
.w-box{
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
}
.carousel-item-pic{
    width: 277.5px;
    height: 300px;
}
.carousel-item:hover{
    /* border: 1px solid #777; */
    background: #367aff;
    box-shadow: 2px 2px 5px #bbb;
    color: #fff;
}
.carousel-item:hover .shijitj-text{
    color: #fff;
}
.carousel-item{
    width: 277.5px;
    height: 420px;
    margin-right: 30px;
    background: #eee;
    box-sizing: border-box;
}
.carousel-box{
    height: 420px;
    width: 1200px;
    overflow: hidden;
    position: relative;
}
.p-h4 .arrow_right{
    display: inline-block;
    width: 25px;
    height: 21px;
    background: url(../../../assets/img/right3.png) no-repeat center center;
    vertical-align: middle;
    transform: translateY(-2px);
}
.p-h4{
    font-size: 22px;
    line-height: 42px;
    font-weight: 600;
}
.picbox{
    position: absolute;
    top: -220px;
    left: 0;
    width: 240px;
    height: 220px;
    background: url(../../../../public/img/xnq/menu1.png) no-repeat center center;
    border: 3px solid #f89807;
}
.submenu-item:nth-child(1){background: #dc5238;}
.submenu-item:nth-child(2){background: #f89807;}
.submenu-item:nth-child(3){background: #1db59b;}
.submenu-item{
    width: 240px;
    text-align: center;
    color: #fff;
    position: relative;
}
.submenu{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    line-height: 50px;
    background: #00a0e9;
    font-size: 22px;
    justify-content: center;
}
.banner{
    height: 630px;
    background: url(../../../../public/img/xnq/banner1.png) no-repeat center center;
}
.bestPerson header{
    position: relative;
    height: 630px;
}
.bestPerson{
    font-family: '微软雅黑';
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>
<!--能人列表-->
<template>
    <div class="bestPerson">
        <div class="breadcrumb w-1400">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/xiangningqing'}">乡宁情</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/Personnelfile'}">人才档案</el-breadcrumb-item>
                <el-breadcrumb-item >人才列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="file w-1400">
            <el-row :gutter="30">
                <el-col :span="18">
                    <div class="main-l">
                        <div class="header">
                            <div class="fl">
                                <h3 class="fl">分类</h3>
                                <ul class="fl clearfix">
                                    <li class="fl header-list">经营专家</li>
                                    <li class="fl header-list">养殖专家</li>
                                    <li class="fl header-list isact">种植专家</li>
                                    <li class="fl header-list">兽医专家</li>
                                </ul>
                            </div>
                            <div class="fr more"><router-link to="/person"> 更多<i class="el-icon-arrow-right"></i></router-link></div>
                        </div>
                        <div class="card clearfix" v-for="item in 10" :key="item+'card'">
                            <router-link to="/persondetail">
                                <div class="fl card-pic">
                                    <el-image :src="require('../../../../public/img/xnq/file1.png')" style="width:100%;height:100%" fit="cover"></el-image>
                                </div>
                                <div class="fl card-info">
                                    <div class="card-name">马全明</div>
                                    <div class="card-intro">多年致力于病虫害处理，有自己的处理方法，效果显著多年致力于病虫害 处理，有自己的处理方法，效果显著多年致力于病虫害处</div>
                                    <div class="card-time">2021-10-09 浏览量 248</div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="main-r">
                        <div class="header">
                            <div class="fl">
                                <h3 class="fl">热门推荐</h3>
                            </div>
                            <div class="fr more">更多<i class="el-icon-arrow-right"></i></div>
                        </div>
                        <div class="card2 clearfix" v-for="item in 10" :key="item+'card'">
                            <div class="fl card2-pic">
                                <el-image :src="require('../../../../public/img/xnq/file2.png')" style="width:100%;height:100%" fit="cover"></el-image>
                            </div>
                            <div class="fl card2-info">
                                <div class="card2-name">马全明</div>
                                <div class="card2-intro">多年致力于病，效果显著多年致力于病虫害 处理，有自己的处理方法，效果显著多年致力于病虫害处</div>
                                <!-- <div class="card-time">2021-10-09 浏览量 248</div> -->
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div style="height:200px"></div>
    </div>
</template>
<script>
import thirdNav from '@/components/thirdNav'
export default {
    components:{
        thirdNav,
    },
    data(){
        return{
            searchkey:''
        }
    },
}
</script>
<style scoped>
.file{
    font-family: '宋体';
}
.card-time{
    font-size: 16px;
    line-height: 65px;
    padding-left: 50px;
}
.card-intro{
    text-indent: 28px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    min-height: 60px;
}
.card2-intro{
    text-indent: 20px;
    font-size: 16px;
    line-height: 20px;
    min-height: 60px;
    font-size: '微软雅黑';
}
.card-name{
    font-size: 22px;
    line-height: 50px;
    font-weight: 700;
}
.card2-name{
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
}
.card2-info{
    height: 110px;
    overflow: hidden;
}
.card-info{
    width: calc(100% - 255px);
    margin-left: 18px;
}
.card2-info{
    width: calc(100% - 150px);
    margin-left: 18px;
}
.card-pic{
    width: 235px;
    height: 175px;
    overflow: hidden;
}
.card2-pic{
    width: 130px;
    height: 110px;
    overflow: hidden;
}
.card,.card2{
    padding: 20px;
    border-bottom: 1px solid #777;;
}
.more{
    margin-top: 3px;
    cursor: pointer;
}
.main-l,.main-r{
    border: 1px solid #777;
}
.header-list.isact{
    background: #0069c9;
    color: #fff;
}
.header-list{
    padding: 3px 12px;
    border: 1px solid #333;
    background: #fff;
    margin: 0 20px;
    box-sizing: border-box;
}
.header h3{
    padding: 3px 12px;
}
.header{
    height: 50px;
    background: #dfdfdf;
    padding-top: 12px;
    font-size: 20px;
    border-bottom: 1px solid #333;
}
.picbox{
    position: absolute;
    top: -220px;
    left: 0;
    width: 240px;
    height: 220px;
    background: url(../../../../public/img/xnq/menu3.png) no-repeat center center;
    border: 3px solid #1db59b;
}
.submenu-item:nth-child(1){background: #dc5238;}
.submenu-item:nth-child(2){background: #f89807;}
.submenu-item:nth-child(3){background: #1db59b;}
.submenu-item{
    width: 240px;
    text-align: center;
    color: #fff;
    position: relative;
}
.submenu{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    line-height: 50px;
    background: #00a0e9;
    font-size: 22px;
    justify-content: center;
}
.banner{
    height: 630px;
    background: url(../../../../public/img/xnq/banner1.png) no-repeat center center;
}
.bestPerson header{
    position: relative;
    height: 630px;
}
.bestPerson{
    font-family: '微软雅黑';
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>